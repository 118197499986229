<template>
  <div class="listing-details" v-if="roomList&& hotelInfo">
    <div class="head" @touchmove.prevent>
      <img
        class="back"
        src="../../assets/img/commoditySpike/back.png"
        alt=""
        @click="$goBack()"
        
      />
     
      <div class="title">{{hotelInfo.name}}</div>
      <img src="../../assets/img/Star.png" v-if="hotelInfo.collectId===null" class="star"   @click="shoucang()" alt="">
      <img src="../../assets/img/readstar.png" v-if="hotelInfo.collectId!=null" class="star"   @click="cCT(hotelInfo.collectId).then(()=>{hotelInfo.collectId=null})"   alt="">

      <img
        class="share"
        src="../../assets/img/takeOutFood/share.png"
        alt=""
        @click="openShare()"
      />
      <van-share-sheet
        v-model="showShare"
        title="立即分享给好友"
         cancel-text=""
        :options="options"
        @select="onSelect"
      />
    </div>

    <div class="main">
      <div class="swiper">
        <van-swipe @change="onChange" ow-indicators=false>
           <van-swipe-item >
              <img :src="hotelInfo.imgUrl" alt="" >
          </van-swipe-item>
          <!-- <van-swipe-item  v-for="item in hotelInfo.imgUrl" :key="item">
              <img :src="item" alt="" v-if="hotelInfo.imgUrl.isArray()&&hotelInfo.imgUrl.length>1">
          </van-swipe-item> -->
          
          <template #indicator>
            <!-- <div class="custom-indicator">{{ current + 1 }}/{{hotelInfo.imgUrl.length}}</div> -->
          </template>
        </van-swipe>
      </div>
      <div class="warp">

      <div class="hotel-info">
        <div class="hotel-name">{{hotelInfo.name}}</div>
        <div class="hotel-content">
          <div class="hotel-box">
            <div class="hotel-location">
                {{returnStart(hotelInfo.hotelLevel)}}
             <span v-if="hotelInfo.levelhotelLevel!=null">  
                ·</span>  距您{{ (parseInt(hotelInfo.distance)).toFixed(1) }}km      
              <!-- · 正大广场 · 明珠广场 -->
              <!-- {{location}} -->
              <!-- {{dd}}
              {{data}} -->
            </div>
            <div class="location-details">
             {{hotelInfo.address}}
            </div>
          </div>
        
          <div class="hotel-icon"  >
            <div class="location" @click="roadNav(hotelInfo.longitude,hotelInfo.latitude,hotelInfo.name)" >
              <img src="../../assets/img/location.png" alt="" />
              <span>导航</span>
            </div>
            <div class="phone">
              <a :href="'tel:'+hotelInfo.phone">
              <img src="../../assets/img/phone.png" alt="" />
              <span>电话</span>
              </a>
            </div>
          </div>
        </div>
         <!-- v-if="coupons.length>0" -->
        <ul class="coupon" v-if="coupons.length>0" >
          <!-- <li v-for="item in coupons.filter(item=> item.enable==='1')" :key="item.id" >
            <div class="coupon-detail" v-if="item.type==='2'"  >{{item.discount}}折</div>
            <p v-if="item.type==='2'"></p>
            <div class="get" v-if="item.type==='2'" @click="getCoupon(item.id)">领</div>

             <div class="coupon-detail" v-if="item.type==='1'"  >{{item.reduceAmount}}元</div>
            <p v-if="item.type==='1'"></p>
            <div class="get" v-if="item.type==='1'" @click="getCoupon(item.id)">领</div>
          </li> -->
          
          <li class="coupon-item" v-for="item in coupons.filter(item=> item.enable==='1')" :key="item.id">
            <div class="left">
             <div class="van-ellipsis">{{item.name}}</div>
              <div class="middle" v-if="item.type==='1'">￥{{item.reduceAmount}}</div>
              <div class="middle" v-else>{{item.discount}}折</div>

              
              <div class="foo">
                满{{item.premiseAmount}}可用
              </div>
            </div>
            <div class="right" v-if="item.couponUser==null">
              <div class="type" v-if="item.type==='1'">代金券</div>
              <div class="type" v-else >折扣券</div>

              <div class="p"  @click="getCoupon(item)">领取</div>
            </div>
            <div class="right" v-else>
              <span>
                已领取
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div class="check-time" v-if="type==0"  >
          <span @click="show=true" >{{startTime.slice(5)}}</span>
       
          <div  @click="show=true"><p> <label>{{dayNum}}晚</label></p></div>
          <span @click="show=true">{{endTime.slice(5)}}</span>

          <div class="last" @click="showNumber=true">{{roomNumber}}间 · {{poepleNum}}人</div>
      </div>

      <div class="check-time"  v-if="type==1"  @click="showDate=true" >
          <span  style="flex:1;">{{startTime.slice(5)}} <span>入住</span></span>
        <!-- @click="show=true" -->
         <img src="../../assets/img/right_black.png" alt="">
      </div>

     

      <ul class="list" v-if="roomList.length">
          <li class="list-item" v-for=" item in roomList" :key="item.id">
             <img class="selld" src="../../assets/selled.png" v-if="  item.roomNum<roomNumber || roomNumber>10 || ( type == 1 && (new Date(startTime.replace(/年|月|日/g,'/').slice(0,10) +' '+ item.useEndTime+':00').getTime() - Date.now()) <=3600 * 1000 )  
                
                 " alt="">
              
              
              <img  class="room-img" :src="item.imgUrl[0]" alt="" @click="goDetail(item , item.roomNum<roomNumber || roomNumber>10 ||( type == 1 && (new Date(startTime.replace(/年|月|日/g,'/').slice(0,10) +' '+ item.useEndTime+':00').getTime() - Date.now()) <=3600 * 1000 ))">
              <!-- 标准房 -->
            <!-- {{$route.query.id}} -->

              <div class="box" v-if="type==='0'">
               
                  <div class="name">
                      {{item.name}}
                  </div>
                  <div class="room-info">
                         {{item.haveBreakfast==='1'?'有早餐':'无早餐'}}<span v-if="item.measure!=null"> · {{item.measure}}㎡</span>  <span v-if="item.bedSize!=null"> · {{item.bedSize}}m床</span> <span v-if="item.occupancy!=null"> · {{item.occupancy}}人房</span>
                  </div>
                  <div class="rule">
                    {{item.cancelPolicy}}
                  </div>
                  <div class="prices">
                      <div class="activeprice">
                          ￥ <span>{{item.price}}</span>
                      </div>
                      起
                      <div class="orginprice">
                          {{item.marketPrice}}
                      </div>
                  </div>
              </div>



              <!-- 钟点房 -->

                  

                <div class="box" v-if="type==='1'"> 
              
                  
                  <div class="name">
                      {{item.name}}
                  </div>
                   <div class="rule">
                     使用时间:{{item.useStartTime}}~{{item.useEndTime}}可住
                  </div> 
                  <div class="room-info">
                  {{item.haveBreakfast==='1'?'有早餐':'无早餐'}}    <span v-show="item.measure!=null"> · {{item.measure}}㎡</span>  <span v-show="item.bedSize!=null"> · {{item.bedSize}}床</span> <span v-show="item.bedNum!=null"> · {{item.bedNum}}人房</span>
                  </div>
                 
                  <div class="prices">
                      <div class="activeprice">
                          ￥ <span>{{item.price}}</span>
                      </div>
                      起
                      <div class="orginprice">
                          {{item.marketPrice}}
                      </div>
                  </div>
                  
              </div>

              <img class="ding" src="../../assets/img/ding.png" 
                @click="goDetail(item,false)"
               v-if="item.roomNum>=roomNumber && roomNumber<11&& ! ( type == 1 && (new Date(startTime.replace(/年|月|日/g,'/').slice(0,10) +' '+ item.useEndTime+':00').getTime() - Date.now()) <=3600 * 1000 )"  alt="">
              <img class="ding" src="../../assets/ding_gery.png" v-else alt="">

          </li>
      </ul>
      <div class="evaluate" v-if="words.length"
      
       >
        <div class="subtitle" >用户评价</div>
        <div class="list" v-for="item,index in words" :key="item.id">
          <div class="info">
            <img :src="item.headimgUrl" alt="">
            <div class="username">{{item.nickName}}
              <span><van-rate v-model="item.logisticsScore" allow-half readonly void-icon="star" void-color="white" color="#ff5200" size=".293333rem" /></span>
            </div>
          </div>
          <div class="words" v-if="item.content">{{item.content}}</div>
          <div class="line" v-if="index !==words.length-1"></div>
        </div>
      </div>
    </div>
    </div>
<van-calendar v-model="show" type="range" @confirm="onConfirm" />
  <van-action-sheet v-model="showNumber" title="入住条件">
  <div class="number">
    <ul>
      <li> <div class="left">间数</div> <div class="right"> <img src="../../assets/roomImg/jian.png" alt="" @click="jian(1)"><div>{{roomNumber}}</div>  <img src="../../assets/roomImg/jia.png" alt="" @click="roomNumber++">   </div> </li>
      <li> <div class="left">人数</div> <div class="right"> <img src="../../assets/roomImg/jian.png" alt="" @click="jian(2)"><div>{{poepleNum}}</div> <img src="../../assets/roomImg/jia.png" alt="" @click="poepleNum++">   </div> </li>

    </ul>
      <div class="close" @click="showNumber=false">确定</div>
  </div>
</van-action-sheet>
<van-calendar v-model="showDate" :show-confirm="false" @select="selectDate" />
  </div>
</template>
<script>
import {getHotelData,getHotelInfo,getComment} from '../../api/hotelDetail/index'
import {getShopCoupons ,userGetCoupon} from "../../api/cop/index"
import {Toast} from 'vant'
export default {
  name:'keep',
  data() {
    return {
      showDate:false,
      
     
      show: false,
      current: 0,
      showShare: false,
     
      roomList:[],
      hotelInfo:null,
      coupons:[],
      startTime:this.formatDate(new Date()), //标准房的显示时间
      endTime:this.formatDate(new Date(new Date().getTime()+1000*60*60*24)), //标准房的显示时间
      dayNum:1,
      words:[],
      id:'',
      type:'0',
      start:'',
      poepleNum:1,
      roomNumber:1,
      data:'',
     
      showNumber:false,
      startfirst:'', //保存时间
      end:'',
      location:{
              latitude:0,
              longitude:0
      },
      options: [
        [
          { name: '微信', icon: 'wechat',index:0 },
          { name: '朋友圈', icon: 'wechat-moments', index:1 },
          { name: 'QQ', icon: 'qq' ,index:2},
        ],
      ],
    };
  },
  watch:{
    showNumber:function(val ,old){
        if(!val&&old){
           this.onConfirm([this.startfirst,this.end])
        }
    }
  },
  methods: {
    selectDate(data){
    console.log(data);
    this.showDate=false
    data = parseInt(data.getTime()/1000) 
    data= this.timestampToTime(data)
    this.getRoomList(data,data)
    this.startTime=this.formatDate(data)
    
    },
    shoucang(){
      this.collection({
        type:'2',
        relationId:this.hotelInfo.id
      }).then(res=>{
        if(res.data.code===0){

        Toast.success('收藏成功')
        this.$set(this.hotelInfo,'collectId','0')

        getHotelInfo({id:this.id,
      Ilatitude :this.location.latitude,
      Ilongitude :this.location.longitude,

    }).then(res=>{
      console.log(res,'酒店详情');
       if(res.data.code===0){
        this.hotelInfo=res.data.data
        getComment({
          
          shopId:this.hotelInfo.id

        }).then(res=>{
          console.log(res,'评论');

          if(res.data.code===0){
            this.words=res.data.data.records.filter((item,index)=>{
            //  console.log(item)
              return index<3
            })
          }
        })
       }
     })
        }
      })
    },
    returnStart(data){
        switch (data){
          case '0' :return ("经济型");
          case '1' : return("舒适型") ;
          case '2' : return("高档型") ;
          case '3' : return("豪华型") ;
        }
  return 
    },

//跳转到客房详情
  goDetail(item ,status){

if(status){ 
  this.$toast('该房型暂无可用房间')
  return
}
    if(item.roomNum<this.roomNumber) return
    this.$router.push({
      path:'roomDetail',
      query:{
        id:item.id,
         dayNum:this.dayNum,
         startTime:this.startTime,
         endTime:this.endTime,
         type:this.$route.query.type||'0',
         roomNum:this.roomNumber,
         session:this.$route.query.session
      }
    })
  },

  //录播图的显示页的切换
    onChange(index) {
      this.current = index;
    },
   
    // 打开分享面板
    openShare() {
      this.showShare = true;
    },
     formatDate(date) {

      return `${date.getFullYear()}年${(date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):date.getMonth() + 1}月${date.getDate()<10?'0'+date.getDate():date.getDate()}日`+ '  '+this.getWeek(date);
    },
    onConfirm(date) {
      console.log(date,35416351651);
      const [start, end] = date;
      this.startfirst=start
      this.end = end
      this.getRoomList(start,end)
      
      this.show = false;
      this.dayNum=(end.getTime()-start.getTime())/(1000*60*60*24)
      this.startTime=this.formatDate(start)
      this.endTime=this.formatDate(end)
    },
   getWeek(data){
     let a = new Array("日", "一", "二", "三", "四", "五", "六"); 
  let str = "周"+ a[ data.getDay()];  
  return str
   } ,
   getCoupon(item){
     userGetCoupon({
       couponId:item.id
     }).then(res=>{
       if(res.data.code===0){
         Toast.success('领取成功')
        //  item.couponUser==1
        //获取优惠券列表
    getShopCoupons({
      shopId:this.id
    }).then(res=>{
      console.log(res,"优惠券");
      if(res.data.code===0){
        this.coupons=res.data.data.records
        console.log(this.coupons)
      }
    })
       }else{
          Toast.fail("重复领取")
       }
     }).catch(()=>{
       Toast.fail("领取失败")
     })
   },
   //客房列表

   getRoomList(start,end){
     console.log(this.roomNumber,'房间数');
     console.log(start,'start');
     console.log(end,'end')
     start = start.getFullYear()+'-'+(start.getMonth()+1)+'-'+start.getDate() ||new Date(new Date().getTime()).getFullYear()+'-'+(new Date(new Date().getTime()).getMonth()+1)+'-'+(new Date(new Date().getTime()).getDate())
     end = end.getFullYear()+'-'+(end.getMonth()+1)+'-'+end.getDate()||new Date(new Date().getTime()).getFullYear()+'-'+(new Date(new Date().getTime()).getMonth()+1)+'-'+(new Date(new Date().getTime()).getDate()+1)
      this.data={
      shopId:this.id,
      checkinDate:start,
      checkoutDate:end,
      roomNum:this.roomNumber+'',
    }
      getHotelData({ 
      shopId:this.id,
      checkinDate:start,
      checkoutDate:end,
      roomNum:this.roomNumber+'',
      roomType:this.$route.query.type ||'1'

    }).then(res=>{
      console.log(res,'客房列表');
      if(res.data.code===0){
        this.roomList=res.data.data
      }
    })
   },
   jian(data){
     if(data===1){
       if( this.roomNumber-1<1){
         return
       }
       this.roomNumber-=1
     }else{
       if(this.poepleNum-1<1){
         return
       }
       this.poepleNum-=1
     }
   },
   onSelect(index){
     let obj={

           title:this.hotelInfo.name,
          picUrl:this.hotelInfo.imgUrl|| "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
          platformId:index.index,
          openUrl:'',
          content:this.hotelInfo.detail
     }
     
    //  window.android.share( obj.platformId,
    //         "3",
    //         obj.title,
    //         obj.content,
    //         obj.openUrl,
    //         obj.picUrl)
       this.shareInfo(obj) 
   },
   timestampToTime(timestamp) {
     
            var date = timestamp;//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            return new Date(parseInt(date) * 1000)
        },


         sendLocation(long,lat){ //获取定位信息的返回函数
          
          // IOS 的返回的是一个数据:'long=177.0000&lat=000000'
          if(this.isIOS()){
              this.location.longitude=long.split('&')[0].split('=')[1]
              this.location.latitude=long.split('&')[1].split('=')[1]
               this.getHotel()
              return
          }
          // Android 没有获取到定位返回的是一个0
        if(long==='0'){
           this.location.longitude='0'
              this.location.latitude='0'
              this.getHotel()
              return       
           }
           // 安卓获取到数据返回的是两个数据
          this.location.longitude=long
          this.location.latitude=lat
           this.getHotel()
  },
  getHotel(){
    //获取酒店详情
    getHotelInfo({id:this.id,
      Ilatitude :  this.location.latitude,
      Ilongitude :  this.location.longitude,
    }).then(res=>{
      console.log(res,'酒店详情');
       if(res.data.code===0){
        this.hotelInfo=res.data.data
        getComment({
          shopId:this.hotelInfo.id
        }).then(res=>{
          console.log(res,'评论');

          if(res.data.code===0){
            this.words=res.data.data.records.filter((item,index)=>{
            //  console.log(item)
              return index<3
            })
          }
        })
       }
    })
  }
  },
  created(){


      if(navigator.userAgent.indexOf('万民')==-1){
               this.openApp()
               return
      }   


    try{ 
          this.$delete(this.$route.query, 'aaa');

    }catch{ 
      //
    }

    window.sendLocation=this.sendLocation
    // let session=this.$route.query.session||'a688b930-0744-4cc4-9008-a52cd8029bda'
    
    // if(session){ 
    //         this.$store.commit('setSession',session)

    //   }

    if(this.$route.query.personNum){
    this.poepleNum= Number(this.$route.query.personNum) + Number(this.$route.query.kidsNum)
    this.roomNumber=this.$route.query.roomNum
    }
    this.type=this.$route.query.type ||'0'
    if(this.$route.query.type!=null){
        this.type=this.$route.query.type
    }

  this.id=this.$route.query.id || 'fe1d4d1847a547268a2a31e3800e5e0e'

  if(this.$route.query.session){
    this.$store.commit('setSession',  this.$route.query.session)
  }
    
    console.log(this.$route.query.id,'qa');
    try {
      if(this.$route.query.start){

      
        let start,end
        if(this.$route.query.start.length===10){
            start = this.timestampToTime(Number(this.$route.query.start)  )
            end=this.timestampToTime(Number(this.$route.query.end) )
        }else{
          start = this.timestampToTime(Number(this.$route.query.start)/1000  )
            end=this.timestampToTime(Number(this.$route.query.end)/1000 )
        }

     this.start=  start
     console.log(this.start,end,'51351351');
     this.onConfirm([start,end])
    }else{
      throw new ('空')
    }
    } catch (error) {
    this.getRoomList()
    }

              //  this.getHotel()

  //获取酒店客房列表
  try {

    
     window.webkit.messageHandlers.sendLocation.postMessage("sendLocation");
  } catch (error) {
    window.android.sendLocation()
  }

   
  
 
  
    //获取优惠券列表
    getShopCoupons({
      shopId:this.id
    }).then(res=>{
      console.log(res,"优惠券");
      if(res.data.code===0){
        this.coupons=res.data.data.records
        console.log(this.coupons)
      }
    })
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
    try{ 

      window.webkit.messageHandlers.statusBarWhite.postMessage(JSON.stringify({status:0}))
    }catch{ 
      //
    }
  },
}
</script>
<style lang="less" scoped>
.number{
  padding: .266667rem .666667rem;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: .4rem;
    .left{
      font-size: 16px;
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: .506667rem;
        height: .506667rem;
      }
      div{
        width: .533333rem;
        height: .533333rem;
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
  }
  .close{
    width:90%;
    height:.993333rem;
     display: flex;
        align-items: center;
        justify-content: center;
        background-image:linear-gradient(to right,#FF7200 ,#FF3C00 ) ;
    color: white;
    border-radius: .48rem;
    margin: .866667rem auto;
  }
}
.listing-details {
  height: 100vh;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F6F6F6 ;
  font-family: PingFangSC-Regular, PingFang SC;
  .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: WHITE;
    padding: .266667rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.013333rem solid #ccc;
    .title {
      margin: auto;
      font-size: 0.453333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      padding-left: 0.16rem;
    }
    .back {
      width: 0.666667rem;
      height: 0.64rem;
      margin-left: 0.266667rem;
      margin-right: .64rem;
    }
    .share {
      width: 0.64rem;
      height: 0.64rem;
      margin-right: 0.426667rem;
    }
    .star{
      width: .64rem;
      height: .64rem;
      margin-right: .266667rem;
    }
  }
  .main::-webkit-scrollbar {
    display: none;
  }
  .main {
   
    box-sizing: border-box;
    padding-bottom: 0.9rem;
   position: fixed;
    top: 1.21rem;
    left: 0;
    width: 100%;
    overflow: auto;
    height: 100%;
    background-color: #F6F6F6 ;
    height: 100vh;
    .swiper {
      width: 100%;
      height:7.2rem;
      // padding-bottom: 0.266667rem;
       margin-bottom:2.213333rem;
       box-sizing: border-box;
      .custom-indicator {
        position: absolute;
        right: 0.066667rem;
        bottom: 0.066667rem;
        padding: 0.026667rem 0.066667rem;
        font-size: 0.16rem;
        background: rgba(0, 0, 0, 0.1);
      }
      .van-swipe {
       
        height: 100%;
                img{
                    width: 100%;
                    height: 7.2rem;
                }
        .custom-indicator{
            width: .906667rem;
            height: .586667rem;
            line-height: .586667rem;
            border-radius: .44rem;
            text-align: center;
            background-color: black;
            opacity: .3;
            position: absolute;
            top: 4.08rem;
            left:8.733333rem;
            color: white;
        }
      }
    }
     .warp{
      width: 100%;
      position: absolute;
      top: 4.8rem;
      left:0;
      padding: 0 .266667rem  2rem;
      box-sizing: border-box;

    }
    .hotel-info {
      width: 9.466667rem;
      padding: 0.266667rem;
      box-sizing: border-box;
     
      background-color: white;
      border-radius: .266667rem;
      margin-bottom: .266667rem;
      .hotel-name {
        font-size: 0.48rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 0.666667rem;
      }
      .hotel-content {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .hotel-location{
            width: 6.133333rem;
            margin-bottom: .133333rem;
        }
        .location-details{
            width: 6.133333rem;
        }
        .hotel-box {
          font-size: 0.32rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
         .hotel-icon {
            display: flex;
            align-items: center;
            .location {
                width: 1.173333rem;
                height: 1.493333rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: .133333rem;
              img {
                width: 0.666667rem;
                height: 0.666667rem;
              }
              span{
                font-size: .32rem;
              }
            }
            .phone{
                width: 1.173333rem;
                height: 1.493333rem;
              
                margin-bottom: .133333rem;
                a{
                  color: black;
                 display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                }
                img{
                     width: 0.666667rem;
                    height: 0.666667rem;
                }
                  span{
                font-size: .32rem;
              }
            }
          }
      }
      .coupon::-webkit-scrollbar{ 
        display: none;
      }
      .coupon{
          display: flex;
          margin-top: .266667rem;
          overflow-x: auto;
          border-radius: .066667rem;

          .coupon-item{ 
            flex-shrink: 0;
            box-sizing: border-box;
            background-image: url('../../assets/img/quan_bg.png');
            width:4rem;
            height: 1.68rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: .32rem;
            .left{ 
              height: 100%;
              width: 67%;
              display: flex;
              flex-direction:column;
              align-items: center;
              
              .van-ellipsis{ 
                transform: scale(0.83);
                opacity: 0.8;
              }
              .middle{ 
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .48rem;
                font-weight: 600;
              }
              .foo{ 
                 transform: scale(0.83);
                opacity: 0.8;
              }
            }
            .right{ 
              display: flex;
              flex-direction: column;
              align-items: center;
              flex: 1;
              .type{ 
                font-size: .32rem;
                transform: scale(0.83);
                opacity: 0.8;
               
              }
               .p{ 
                 width: 1.066667rem;
                 height: .4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: white;
                  border-radius: .2rem;
                  color: #FF5200;
                  transform:scale(0.83);
                  margin-top: .133333rem;
                }
            }
          }
          li{
              width: 1.946667rem;
              height: .586667rem;
              display: flex;
              align-items: center;
               color: white;
               box-sizing: border-box;
               margin-right : .2rem;
              
              .coupon-detail{
                  width: 60%;
                  background-color: #FF5200FF;
                  font-size: .293333rem;
                  border-radius: 0 .066667rem .066667rem 0 ;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              p{
                  width: 0;
                  height: 100%;
                  border-left: .013333rem dashed #FF5200FF ;
              }
              .get{
                  background-color: #FF5200FF;
                  flex: 1;
                  font-size: .266667rem;
                  color: #ccc;
                  border-radius: .066667rem 0 0 .066667rem ;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               
              }
          }
      }
    }
   
    .check-time{
        padding:0 .426667rem;
        display: flex;
        font-size: .32rem;
        color: #333;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 34px;
        align-items: center;
        background-color: white;
        border-radius:.133333rem;
        span{
            margin-right: .133333rem;
        }
        img{
          width: .373333rem;
          height: .373333rem;
          
        }
        div{
            width: 1.2rem;
            margin-right: .133333rem;
            position: relative;
            height: .013333rem;
            background-color: #FF5200FF;
            p{
                position: absolute;
                width: .933333rem;
                height: .4rem;
                background: #FFFFFF;
                border-radius: 21px;
                border: 1px solid #FF5200;
                top: -0.2rem;
                left: 50%;
                transform: translate(-50%);
              
                label{
                    display: block;
                    width: 100%;
                    height: 100%;
                      font-size: .32rem;
                     transform: scale(0.83);
                    display: flex;
                    align-items: center;
                     justify-content: center;
                }
            }
            
        }
        .last{
                height: 100%;
                background-color: white;
                width: 1.413333rem;
                text-align: center;
                line-height:.453333rem ;
                flex: 1;
                text-align: right;
            }
    }
    .list{
        padding: .266667rem 0 0;
        .list-item{
            display: flex;
            align-items: center;
            font-size: .32rem;
            padding: .266667rem;
            margin-bottom: .266667rem;
              background-color: white;
              border-radius: .133333rem;
                position: relative;
                 .selld{ 
                  width: 1.52rem;
                  height: 1.52rem;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
            .room-img{
                width: 2.426667rem;
                height: 2.426667rem;
                border-radius: 10%;
               
            }
            .box{
                flex: 1;
                color: #999999FF;
              
              
                margin-left: .266667rem;
               
                .name{
                    color: #333333FF;
                    font-size: .4rem;
                    margin-bottom: .133333rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                   
                }
                .room-info{
                    margin-bottom: .133333rem;
                }
                .rule{
                    margin-bottom: .133333rem;

                }
                .prices{
                    display: flex;
                    align-items: center;
                    .activeprice{
                        color: #A80000FF;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                       
                        span{
                            font-size: .4rem;
                             margin-right: .133333rem;
                        }
                    }
                    .orginprice{
                            text-decoration: line-through;
                            margin-left: .133333rem;
                        }
                }
            }
            .ding{
                width: .986667rem;
                height: .986667rem;
            }
        }

    }
  }
  .footer {
    display: flex;
    padding: 0.266667rem 0.426667rem 0.266667rem 0.666667rem;
    border-top: 1px solid #eee;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    .collection {
      display: flex;
      flex-direction: column;
      margin-right: 0.666667rem;
      img {
        width: 0.666667rem;
        height: 0.64rem;
      }
      div {
        font-size: 0.32rem;
        transform: scale(0.83);
      }
    }
    .btns {
      display: flex;
      flex: 1;
      color: white;
      font-size: 0.186667rem;
      text-align: center;
      align-items: center;
      height: 0.96rem;
      .talk {
        width: 50%;
        background-image: url("../../assets/img/btn1_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        line-height: 0.96rem;
      }
      .get-phone {
        width: 50%;
        background-image: url("../../assets/img/btn2_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        line-height: 0.96rem;
      }
    }
  }
  .evaluate{
    margin: .266667rem 0 0;
    background-color: white;
    border-radius: .133333rem;
    padding-top: .266667rem;
    box-sizing: border-box;
    .subtitle{
      padding-left: .426667rem;
      font-size: .48rem;
      padding-bottom: .266667rem;
      font-weight: 600;
    }
     .line:nth-last-of-type(1){
     background-color: transparent;
   }
    .list{
      box-sizing: border-box;
      padding:  .266667rem .266667rem 0;
      .info{
        display: flex;
        align-items: center;
        padding-bottom: .213333rem;

        img{
          width: 1.733333rem;
          height: 1.733333rem;
          border-radius: 50%;
        }
        .username{
          font-size: .373333rem;
          color: #333;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-weight: 600;
          span{
            display: block;
           padding-top: .16rem;
          }
        }
      }
      .words{
        font-size: .32rem;
        color: #333;
        line-height: .453333rem;
      }
      .line{
        width:100%;
        height: 1px;
        background-color: #eee;
         
      }
    }
  
  }
}
</style>